<template>
  <div
    :class="{ borderless: !!borderless }"
    class="data-filter has-margin-bottom-75"
  >
    <div v-if="!activeFilters.length && !name" class="faux-button">
      <b-icon icon="plus" size="is-small" />

      <span>{{ btnAddLabel }}</span>
    </div>

    <div
      v-for="(filter, index) in activeFilters"
      :key="index"
      :class="{
        focused: !$_.isEmpty(focusedFilter) && filter.key === focusedFilter.key
      }"
      class="active-filter"
    >
      <filter-form
        v-if="selectedFilter === filter"
        v-click-outside="clearSelected"
        :filter="filter"
        @close="clearSelected"
        @applyFilter="applyFilter"
      />

      <span @click.stop="setSelectFilter(filter)">
        {{ formatFilterLabel(filter) }}:&nbsp;
        <strong>'{{ formatFilterValue(filter) }}'</strong>
      </span>

      <a
        role="button"
        tabindex="0"
        class="delete"
        @click="removeFilter(filter)"
      />
    </div>

    <div
      v-show="activeFilters.length < filters.length"
      class="autocomplete-holder"
    >
      <b-autocomplete
        ref="autocomplete"
        v-model="name"
        :data="filteredFiltersArray"
        :clear-on-select="true"
        :open-on-focus="true"
        :keep-first="true"
        :placeholder="activeFilters.length ? btnAddLabel : ''"
        field="label"
        expanded
        @focus="onFocusAutocomplete"
        @blur="onBlurAutocomplete"
        @select="setSelectFilter"
        @click.native.stop
      >
        <template slot="empty">No filters found</template>
      </b-autocomplete>

      <filter-form
        v-if="showAddFilter"
        v-click-outside="clearSelected"
        :filter="selectedFilter"
        :class="'new-filter'"
        @close="clearSelected"
        @applyFilter="applyFilter"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    "filter-form": () => import("@components/data-filter/filter-form")
  },
  props: {
    btnAddLabel: { default: "Add filter", type: String },
    filters: {
      default() {
        return [];
      },
      type: Array,
      required: true
    },
    activeFilters: {
      default() {
        return [];
      },
      type: Array,
      required: true
    },
    borderless: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => {
    return {
      name: "",
      isAddButtonActive: true,
      autocompleteFocused: false,
      selectedFilter: {},
      focusedFilter: {}
    };
  },
  computed: {
    isSelectedFilterEmpty() {
      return this.$_.isEmpty(this.selectedFilter);
    },
    filteredFiltersArray() {
      const hasActiveDateFilter =
        this.$_.findIndex(this.activeFilters, ["type", "date"]) !== -1;

      return this.filters.filter(filter => {
        if (filter.type === "date" && hasActiveDateFilter) {
          return false;
        }

        return (
          filter.label
            .toString()
            .toLowerCase()
            .indexOf(this.name.toLowerCase()) !== -1 &&
          this.$_.findIndex(this.activeFilters, ["key", filter.key]) === -1
        );
      });
    },
    showAddFilter() {
      return (
        !this.isSelectedFilterEmpty &&
        this.$_.findIndex(this.activeFilters, [
          "key",
          this.selectedFilter.key
        ]) === -1
      );
    }
  },
  beforeMount() {
    window.addEventListener("keyup", this.navigateThouthFilters);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.navigateThouthFilters);
  },
  methods: {
    setSelectFilter(filter) {
      if (filter === null) {
        return;
      }
      this.name = "";
      this.selectedFilter = filter;
    },
    clearSelected() {
      this.selectedFilter = {};
    },
    applyFilter(filter) {
      this.clearSelected();
      if (filter.value.length === 0) {
        return;
      }

      if (this.addOrUpdateFilter(filter)) {
        this.emitFiltersChanged();
      }

      this.focusAutocomplete();
    },
    onFocusAutocomplete() {
      this.focusedFilter = {};
      this.autocompleteFocused = true;
    },
    onBlurAutocomplete() {
      this.autocompleteFocused = false;
    },
    focusAutocomplete() {
      this.$refs.autocomplete.focus();
      this.onFocusAutocomplete();
    },
    navigateThouthFilters(event) {
      switch (event.code) {
        case "Backspace":
          if (
            this.autocompleteFocused &&
            this.activeFilters.length > 0 &&
            this.$_.isEmpty(this.focusedFilter) &&
            this.getAutocompleteInputEl().value.length === 0
          ) {
            this.getAutocompleteInputEl().blur();
            this.$refs.autocomplete.isActive = false;
            this.focusedFilter = this.$_.clone(
              this.$_.last(this.activeFilters)
            );
          } else if (!this.$_.isEmpty(this.focusedFilter)) {
            this.removeFilter(this.focusedFilter);
            this.focusAutocomplete();
          }
          return;

        case "Escape":
          this.clearSelected();
          return;

        case "ArrowLeft":
          if (
            this.autocompleteFocused &&
            this.activeFilters.length > 0 &&
            this.$_.isEmpty(this.focusedFilter) &&
            this.getAutocompleteInputEl().value.length === 0
          ) {
            this.getAutocompleteInputEl().blur();
            this.$refs.autocomplete.isActive = false;
            this.focusedFilter = this.$_.clone(
              this.$_.last(this.activeFilters)
            );
          } else if (
            !this.$_.isEmpty(this.focusedFilter) &&
            this.activeFilters.length > 1
          ) {
            const index = this.$_.findIndex(this.activeFilters, [
              "key",
              this.focusedFilter.key
            ]);
            if (index === 0) {
              this.focusedFilter = this.$_.clone(
                this.$_.last(this.activeFilters)
              );
              return;
            }
            this.focusedFilter = this.$_.clone(this.activeFilters[index - 1]);
          }
          return;

        case "ArrowRight":
          if (!this.$_.isEmpty(this.focusedFilter)) {
            if (this.activeFilters.length > 1) {
              const index = this.$_.findIndex(this.activeFilters, [
                "key",
                this.focusedFilter.key
              ]);
              if (index === this.activeFilters.length - 1) {
                this.focusAutocomplete();
                return;
              }
              this.focusedFilter = this.$_.clone(this.activeFilters[index + 1]);
            } else if (this.activeFilters.length === 1) {
              this.focusAutocomplete();
              return;
            }
          }
          return;

        default:
          return;
      }
    },
    addOrUpdateFilter(filter) {
      const index = this.$_.findIndex(this.activeFilters, ["key", filter.key]);
      let areActiveFiltersChanged = false;

      if (index !== -1) {
        if (
          this.activeFilters[index].value !== filter.value ||
          this.activeFilters[index].operator !== filter.operator
        ) {
          this.activeFilters[index].value = filter.value;
          this.activeFilters[index].operator = filter.operator;
          areActiveFiltersChanged = true;
        }
      } else {
        areActiveFiltersChanged = true;
        this.activeFilters.push(filter);
      }

      return areActiveFiltersChanged;
    },
    getAutocompleteInputEl() {
      return this.$refs.autocomplete.$el.querySelector("input");
    },
    removeFilter(filter) {
      const index = this.$_.findIndex(this.activeFilters, ["key", filter.key]);
      this.activeFilters.splice(index, 1);
      if (this.focusedFilter === filter) {
        this.focusedFilter = {};
      }
      this.emitFiltersChanged();
      if (
        !this.isSelectedFilterEmpty &&
        filter.key === this.selectedFilter.key
      ) {
        this.clearSelected();
      }
    },
    emitFiltersChanged() {
      this.$emit("filtersChanged", this.activeFilters);
    },
    formatFilterValue(filter) {
      if (
        filter.type === "string" ||
        filter.type === "number" ||
        filter.type === "email"
      ) {
        return filter.value;
      }

      if (filter.type === "date") {
        return this.$moment(filter.value).format("Do MMM, YYYY");
      }

      if (filter.type === "select-radio" || filter.type === "select") {
        return this.$_.find(filter.options, ["value", filter.value]).label;
      }

      let valueLabels = [];
      this.$_.forEach(filter.value, value => {
        valueLabels.push(this.$_.find(filter.options, ["value", value]).label);
      });

      return valueLabels.join(", ");
    },
    formatFilterLabel(filter) {
      if (filter.operator === "equals") {
        return filter.label;
      }

      return `${filter.label} ${filter.operator
        .toLowerCase()
        .replace(/-/g, " ")}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.data-filter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 0.25em;
  &:not(.borderless) {
    border: 1px solid #dbdbdb;
    box-shadow: inset 0 1px 2px rgba(21, 30, 41, 0.1);
    border-radius: 4px;
  }
}
.faux-button {
  position: absolute;
  left: 0.25em;
  top: 0.25em;
  padding: 0.1875em 0.75em 0.1875em 0.75em;
  border-radius: 1em;
  margin: 0.25em;
  border: 1px dashed;
  color: #cccccc;
}
.active-filter {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 0.25em 0.5em 0.25em 0.75em;
  background: whitesmoke;
  border-radius: 1em;
  margin: 0.25em;

  &.focused {
    background: $primary;
  }

  span {
    margin-right: 0.5em;
  }
}
.autocomplete-holder {
  flex-grow: 1;
}
.autocomplete /deep/ input {
  border: none;
  box-shadow: none;
  height: 2.5rem;
  max-height: 2.5rem;
  background: transparent;
}
</style>
